import styled from 'styled-components';

import TableCell from '../TableCell';
import type { TableRowProps } from './types';

export const StyledTableRow = styled.tr<TableRowProps>`
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary[50] : 'transparent'};
  width: 100%;
  height: ${({ theme, compact }) =>
    compact ? theme.spacing[10] : theme.spacing[14]};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  font-weight: ${({ strong, theme }) =>
    strong ? theme.fontWeight.medium : theme.fontWeight.regular};
  color: ${({ theme, strong }) =>
    strong ? theme.colors.neutral[900] : theme.colors.neutral[600]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  font-size: ${({ theme, compact }) =>
    compact ? theme.fontSize.xs : theme.fontSize.sm};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme, onClick }) =>
      onClick && theme.colors.neutral[100]};
  }
`;

export const SelectableCell = styled(TableCell)`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CancelableCell = styled(TableCell)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;
