import React, { forwardRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import * as S from './DatePicker.styles';

import Card from '@ui/Card';
import {
  Calendar,
  ChevronLeft,
  ChevronRight,
  X as Close,
} from '@ui/Icon/outline';
import IconButton from '@ui/IconButton';
import Input from '@ui/Input';

import type { CustomHeaderProps, DatePickerProps } from './types';

function CustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: CustomHeaderProps) {
  return (
    <S.Header>
      <IconButton
        compact
        rounded
        onClick={decreaseMonth}
        variant="transparent"
        color="neutral"
        disabled={prevMonthButtonDisabled}
      >
        <ChevronLeft />
      </IconButton>

      <span className="date-picker-current-month">
        {/* FIXME: Use locale from settings */}
        {format(date, 'MMM yyyy')}
      </span>

      <IconButton
        compact
        rounded
        onClick={increaseMonth}
        variant="transparent"
        color="neutral"
        disabled={nextMonthButtonDisabled}
      >
        <ChevronRight />
      </IconButton>
    </S.Header>
  );
}

export const DATE_FORMAT = 'dd/MM/yyyy';
export const TIME_FORMAT = 'kk:mm';

// FIXME: Correct ForwardRef type
function DatePicker(
  {
    id,
    maxDate,
    minDate,
    maxTime,
    minTime,
    name,
    placeholder,
    disabled,
    dateFormat,
    timeFormat,
    timeIntervals,
    showTimeSelect,
    showTimeSelectOnly,
    startDate,
    endDate,
    value,
    selectsStart,
    selectsEnd,
    error,
    fullWidth,
    selectsRange,
    onChange,
    onClear,
  }: DatePickerProps,
  ref: any
) {
  const intl = useIntl();
  const [internalValue, setInternalValue] = useState<string | null | undefined>(
    null
  );

  const selected = value || internalValue;

  const handleChange = (date: Date | Date[]) => {
    let start, end;
    if (Array.isArray(date)) {
      start = date[0].toLocaleDateString('es');
      end = date[1] ? date[1].toLocaleDateString('es') : undefined;
    } else {
      start = date.toLocaleDateString('es');
    }

    if (selectsRange) {
      onChange?.(start, end);
    } else {
      onChange?.(start);
    }
    setInternalValue(start);
  };

  const handleClear = () => {
    if (!disabled) {
      onClear?.();
      setInternalValue(null);
    }
  };

  const getNewDate = (date: string) => {
    let newDate = date;
    if (!date) {
      return new Date();
    }
    if (date.includes('T')) {
      return new Date(newDate);
    }
    if (date.includes(' ')) {
      newDate = new Date(
        date.split(' ')[0].replaceAll('-', '/')
      ).toLocaleDateString('es');
    }

    const [day, month, year] = newDate.includes('-')
      ? newDate.split('-').map(Number)
      : newDate.split('/').map(Number);

    const returnDate = new Date(year, month - 1, day); // Month is 0-based
    return returnDate;
  };

  return (
    <S.DateInput fullWidth={fullWidth}>
      <ReactDatePicker
        id={id}
        name={name}
        customInput={
          <Input
            error={error}
            placeholder={placeholder}
            fullWidth={fullWidth}
            ref={ref}
            endIcon={
              !value ? (
                <Calendar size={18} />
              ) : (
                <Close onClick={handleClear} size={18} />
              )
            }
          />
        }
        placeholderText={placeholder}
        minDate={minDate && getNewDate(minDate)}
        maxDate={maxDate && getNewDate(maxDate)}
        minTime={minTime && getNewDate(minTime)}
        maxTime={maxTime && getNewDate(maxTime)}
        startDate={startDate && getNewDate(startDate)}
        endDate={endDate && getNewDate(endDate)}
        selected={selected && getNewDate(selected)}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        autoComplete="off"
        popperComponent={Card}
        disabled={disabled}
        dateFormat={DATE_FORMAT}
        timeFormat={timeFormat || TIME_FORMAT}
        timeIntervals={timeIntervals}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        locale={intl.locale}
        onChange={handleChange}
        calendarClassName="date-picker-calendar"
        clearButtonClassName="date-picker-clear-button"
        dayClassName={(date: string) => 'date-picker-day'}
        weekDayClassName={(date: string) => 'date-picker-weekday'}
        popperClassName="date-picker-popper"
        timeClassName="date-picker-time"
        wrapperClassName="date-picker-wrapper"
        showPopperArrow={false}
        renderCustomHeader={CustomHeader}
        selectsRange={selectsRange}
      />
    </S.DateInput>
  );
}

export default forwardRef(DatePicker);
