import React from 'react';

import * as S from './Loader.styles';

interface LoaderProps {
  fullWidth?: boolean;
}

function Loader({ fullWidth }: LoaderProps) {
  return (
    <S.Container fullWidth={fullWidth}>
      <S.Image>
        <svg
          width="150px"
          height="150px"
          viewBox="0 0 1172 257"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M53.4889 26.8515C58.7022 18.8269 65.8966 12.3455 75.0721 7.4073C84.2476 2.4691 94.9871 0 107.291 0C121.679 0 134.713 3.60077 146.391 10.8023C158.069 18.0039 167.244 28.2918 173.917 41.6661C180.799 55.0404 184.24 70.5752 184.24 88.2704C184.24 105.966 180.799 121.603 173.917 135.183C167.244 148.558 158.069 158.948 146.391 166.356C134.713 173.557 121.679 177.158 107.291 177.158C95.1956 177.158 84.4561 174.689 75.0721 169.751C65.8966 164.813 58.7022 158.434 53.4889 150.615V256.787H0V2.46911H53.4889V26.8515ZM129.812 88.2704C129.812 75.1018 126.059 64.8139 118.551 57.4066C111.253 49.7935 102.181 45.987 91.3377 45.987C80.7025 45.987 71.6313 49.7935 64.1241 57.4066C56.8254 65.0197 53.1761 75.4105 53.1761 88.579C53.1761 101.748 56.8254 112.138 64.1241 119.751C71.6313 127.364 80.7025 131.171 91.3377 131.171C101.973 131.171 111.044 127.364 118.551 119.751C126.059 111.933 129.812 101.439 129.812 88.2704Z"
            fill="#0B0B0C"
          />
          <path
            d="M192.282 88.2704C192.282 70.5752 195.618 55.0404 202.291 41.6661C209.173 28.2918 218.453 18.0039 230.131 10.8023C241.809 3.60077 254.842 0 269.231 0C281.534 0 292.274 2.4691 301.449 7.4073C310.833 12.3455 318.028 18.8269 323.032 26.8515V2.46911H376.521V174.689H323.032V150.307C317.819 158.331 310.52 164.813 301.136 169.751C291.961 174.689 281.221 177.158 268.918 177.158C254.738 177.158 241.809 173.557 230.131 166.356C218.453 158.948 209.173 148.558 202.291 135.183C195.618 121.603 192.282 105.966 192.282 88.2704ZM323.032 88.579C323.032 75.4105 319.279 65.0197 311.772 57.4066C304.473 49.7935 295.506 45.987 284.871 45.987C274.236 45.987 265.164 49.7935 257.657 57.4066C250.358 64.8139 246.709 75.1018 246.709 88.2704C246.709 101.439 250.358 111.933 257.657 119.751C265.164 127.364 274.236 131.171 284.871 131.171C295.506 131.171 304.473 127.364 311.772 119.751C319.279 112.138 323.032 101.748 323.032 88.579Z"
            fill="#0B0B0C"
          />
          <path
            d="M584.13 2.46911L474.65 256.478H417.095L457.133 168.825L386.128 2.46911H445.873L486.224 110.184L526.262 2.46911H584.13Z"
            fill="#0B0B0C"
          />
          <path
            d="M583.454 88.2704C583.454 70.5752 586.79 55.0404 593.463 41.6661C600.345 28.2918 609.625 18.0039 621.302 10.8023C632.98 3.60077 646.014 0 660.403 0C672.706 0 683.445 2.4691 692.621 7.4073C702.005 12.3455 709.199 18.8269 714.204 26.8515V2.46911H767.693V174.689H714.204V150.307C708.991 158.331 701.692 164.813 692.308 169.751C683.133 174.689 672.393 177.158 660.09 177.158C645.909 177.158 632.98 173.557 621.302 166.356C609.625 158.948 600.345 148.558 593.463 135.183C586.79 121.603 583.454 105.966 583.454 88.2704ZM714.204 88.579C714.204 75.4105 710.451 65.0197 702.943 57.4066C695.645 49.7935 686.678 45.987 676.042 45.987C665.407 45.987 656.336 49.7935 648.829 57.4066C641.53 64.8139 637.881 75.1018 637.881 88.2704C637.881 101.439 641.53 111.933 648.829 119.751C656.336 127.364 665.407 131.171 676.042 131.171C686.678 131.171 695.645 127.364 702.943 119.751C710.451 112.138 714.204 101.748 714.204 88.579Z"
            fill="#0B0B0C"
          />
          <path
            d="M903.358 0.617283C923.794 0.617283 940.06 7.20155 952.155 20.3701C964.458 33.3329 970.61 51.2339 970.61 74.0731V174.689H917.434V81.1717C917.434 69.6492 914.41 60.6987 908.363 54.3202C902.315 47.9417 894.182 44.7525 883.964 44.7525C873.746 44.7525 865.613 47.9417 859.566 54.3202C853.518 60.6987 850.495 69.6492 850.495 81.1717V174.689H797.006V2.46911H850.495V25.3083C855.917 17.6952 863.215 11.7282 872.391 7.4073C881.566 2.88063 891.889 0.617283 903.358 0.617283Z"
            fill="#0B0B0C"
          />
          <path
            d="M987.76 88.2704C987.76 70.5752 991.097 55.0404 997.77 41.6661C1004.65 28.2918 1013.93 18.0039 1025.61 10.8023C1037.29 3.60077 1050.32 0 1064.71 0C1077.01 0 1087.75 2.4691 1096.93 7.4073C1106.31 12.3455 1113.51 18.8269 1118.51 26.8515V2.46911H1172V174.689H1118.51V150.307C1113.3 158.331 1106 164.813 1096.62 169.751C1087.44 174.689 1076.7 177.158 1064.4 177.158C1050.22 177.158 1037.29 173.557 1025.61 166.356C1013.93 158.948 1004.65 148.558 997.77 135.183C991.097 121.603 987.76 105.966 987.76 88.2704ZM1118.51 88.579C1118.51 75.4105 1114.76 65.0197 1107.25 57.4066C1099.95 49.7935 1090.98 45.987 1080.35 45.987C1069.71 45.987 1060.64 49.7935 1053.14 57.4066C1045.84 64.8139 1042.19 75.1018 1042.19 88.2704C1042.19 101.439 1045.84 111.933 1053.14 119.751C1060.64 127.364 1069.71 131.171 1080.35 131.171C1090.98 131.171 1099.95 127.364 1107.25 119.751C1114.76 112.138 1118.51 101.748 1118.51 88.579Z"
            fill="#0B0B0C"
          />
        </svg>
      </S.Image>
    </S.Container>
  );
}

export default Loader;
