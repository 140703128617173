import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  fullWidth?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 9999;
    `}
`;

const pulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

export const Image = styled.div`
  animation: ${pulse} 2s ease-in-out infinite;
`;
