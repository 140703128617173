import styled from 'styled-components';

import { cardStyles } from '@ui/Card/Card.styles';

interface DateInputProps {
  fullWidth?: boolean;
}

export const DateInput = styled.div<DateInputProps>`
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.neutral[500]};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.sans};
    font-weight: ${({ theme }) => theme.fontWeight.light};
    height: 28px;
    width: 28px;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: ${({ fullWidth }) => (fullWidth ? 'block' : 'inline-block')};
  }
  .date-picker-calendar {
    ${cardStyles}
    box-shadow: ${({ theme }) => theme.boxShadow.md};
  }
  .date-picker-clear-button {
  }
  .date-picker-day {
    color: ${({ theme }) => theme.colors.neutral[900]};
    background-color: transparent;
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.sans};
    font-weight: ${({ theme }) => theme.fontWeight.light};
    height: 28px;
    width: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 50%;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary[50]};
    }
  }
  .date-picker-day.react-datepicker__day--today {
    height: 28px;
    width: 28px;
    background-color: ${({ theme }) => theme.colors.primary[50]};
    border-radius: 50%;
    font-weight: ${({ theme }) => theme.fontWeight.light};
    color: ${({ theme }) => theme.colors.primary[500]};
  }
  .date-picker-day.react-datepicker__day--selected {
    color: ${({ theme }) => theme.colors.neutral[50]};
    height: 28px;
    width: 28px;
    background-color: ${({ theme }) => theme.colors.primary[500]};
    border-radius: 50%;
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }
  .date-picker-day.react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.neutral[500]};
  }
  .react-datepicker__month {
    margin: ${({ theme }) => theme.spacing[2]};
    margin-top: 0;
  }
  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.neutral[500]};
  }
  .date-picker-weekday {
  }
  .date-picker-popper {
    position: fixed !important;
    z-index: 1000 !important;
  }
  .date-picker-time {
  }
  .date-picker-wrapper {
  }
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing[2]};
  margin-bottom: ${({ theme }) => theme.spacing[4]};

  .date-picker-current-month {
    text-transform: capitalize;
    font-weight: ${({ theme }) => theme.fontWeight.light};
    color: ${({ theme }) => theme.colors.neutral[900]};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.sans};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;
